import React from 'react';
import { Link } from 'react-router-dom';

import PageHeading from '../../../layout/PageHeading/PageHeading';
import BottomShadow from '../../../layout/BottomShadow/BottomShadow';

import './Dashboard.css';

const Dashboard = (props) => {
  return (
    <div id='employeeDashboardPage'>
      <div className='centerContainer'>
        <PageHeading
          config={{
            preHeading: 'Stellar Industrial Employee Portal',
            heading: 'Employee Dashboard',
            description: '',
            graphic: 'fas fa-tachometer-alt',
          }}
        />

        <div className='contentBox dashboardSec1'>
          <h3 style={{ marginTop: '0px' }}>Employee Tools:</h3>
          <div className='flex'>
            {/* <div className='leftDiv-outer'>
              <div className='flexLinkContent'>
                <div className='leftDiv'>
                  <Link to='/expedite'>
                    <i className='fas fa-truck fa-fw' />
                  </Link>
                </div>
                <div className='rightDiv'>
                  <Link to='/expedite'>
                    <h3>Order Expedite / Tracking</h3>
                    Convenient fast order look up
                  </Link>
                </div>
              </div>
            </div> */}
            <div className='leftDiv-outer'>
              <div className='flexLinkContent'>
                <div className='leftDiv'>
                  <Link to='/employee/prodfuzzymatch'>
                    <i className='fas fa-list-alt fa-fw' />
                  </Link>
                </div>
                <div className='rightDiv'>
                  <Link to={{ pathname: "https://appsportal.stellarindustrial.com/itemsearch" }} 
                  target="_blank">
                    <h3>Product ID Helper</h3>
                    Search P21 parts using plain english
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomShadow />
      </div>
    </div>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
