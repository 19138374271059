import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { logout } from "../../../actions/auth";
import "./SideBar.css";

const SideBar = ({ logout, auth: { isAuthenticated, loading, user } }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const { permissions = {} } = user;
  const { admin = false, solutions = false } = permissions;

  return loading ? (
    <Fragment></Fragment>
  ) : (
    <div
      className={sideBarOpen ? "layout_sideBar" : "layout_sideBar collapsed"}
    >
      <ul>
        {isAuthenticated && (
          <li>
            <Link
              to="/employee/dashboard"
              onClick={(e) => setSideBarOpen(false)}
            >
              <i className="fas fa-tachometer-alt fa-fw"></i>
              {sideBarOpen && "Your Dashboard"}
            </Link>
          </li>
        )}
        {/* <li>
          <Link to="/expedite" onClick={(e) => setSideBarOpen(false)}>
            <i className="fas fa-truck fa-fw"></i>
            {sideBarOpen && "Order Tracking"}
          </Link>
        </li> */}
        {!isAuthenticated && (
          <li>
            <Link to="/login" onClick={(e) => setSideBarOpen(false)}>
              <i className="fas fa-ellipsis-h fa-fw"></i>
              {sideBarOpen && "Login For More"}
            </Link>
          </li>
        )}
        {isAuthenticated && (
          <li>
            <Link
              to={{ pathname: "https://appsportal.stellarindustrial.com/itemsearch" }} 
              target="_blank"
              onClick={(e) => setSideBarOpen(false)}
            >
              <i className="fas fa-list-alt fa-fw"></i>
              {sideBarOpen && "Product ID Helper"}
            </Link>
          </li>
        )}

        {isAuthenticated && solutions && (
          <li>
            <Link
              to="/employee/solutions/dashboard"
              onClick={(e) => setSideBarOpen(false)}
            >
              <i className="fas fa-draw-polygon fa-fw"></i>
              {sideBarOpen && "Solutions"}
            </Link>
          </li>
        )}
      </ul>

      {isAuthenticated && admin && (
        <div className="loginSymbol">
          <div>
            <Link to="/admin/dashboard" onClick={(e) => setSideBarOpen(false)}>
              <i className="fas fa-user-shield fa-fw"></i>
              {sideBarOpen && "Admin Dashboard"}
            </Link>
          </div>
        </div>
      )}
      {isAuthenticated && (
        <div className="loginSymbol">
          <div>
            <Link
              to="/employee/accountsettings"
              onClick={(e) => setSideBarOpen(false)}
            >
              <i className="fas fa-user-cog fa-fw"></i>
              {sideBarOpen && "Profile"}
            </Link>
          </div>
        </div>
      )}
      <div className="loginSymbol">
        {isAuthenticated ? (
          <div onClick={(e) => logout()}>
            <i className="fas fa-sign-out-alt fa-fw"></i>
            {sideBarOpen && "Logout"}
          </div>
        ) : (
          <Link to="/login">
            <i className="fas fa-sign-in-alt fa-fw"></i>
            {sideBarOpen && "Login"}
          </Link>
        )}
      </div>
      <div
        className="bottomSymbol"
        onClick={(e) => setSideBarOpen(!sideBarOpen)}
      >
        <i
          className={
            sideBarOpen
              ? "fas fa-chevron-left fa-fw"
              : "fas fa-chevron-right fa-fw"
          }
        ></i>
        {sideBarOpen && "Collapse"}
      </div>
    </div>
  );
};
/*

      
      */

/*
{isAuthenticated && (
          <li>
            <Link
              to='/employee/jobcontracts'
              onClick={(e) => setSideBarOpen(false)}
            >
              <i className='fas fa-file-contract fa-fw'></i>
              {sideBarOpen && 'Job Contract Analyzer'}
            </Link>
          </li>
        )}
        */
SideBar.propTypes = {};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(SideBar);
